import withTrim, {
  TrimInput,
  TrimInputNumber,
  TrimPswInput,
  TrimSearch,
  TrimTextArea,
} from './WithTrim/index'
import MyMessage from './Message/index'

// 下载登录
import InfDownLoadModal from './InfDownLoadModal/index'

// PIN码验证框
import InfCheckPINModal from './InfCheckPINModal/index'

// 客户验证
import InfPhoneCheckModal from './InfPhoneCheckModal/index'

// 瀑布流
import InfMasonryImgs from './InfMasonryImgs/index'

// 错误页面

import InfErrorPage from './InfErrorPage/index'

// 查看弹窗

import PreviewCarouselModal from './PreviewCarouselModal'

// 空数据页面
import InfoEmptyData from './InfoEmptyData'

// 列表页脚
import InfListFooterText from './InfListFooterText'

// 分享气泡窗
import SharePopover from './SharePopover'

// 公共页底
import CommonFooter from './CommonFooter'

export default {
  CommonFooter,
  withTrim,
  TrimInput,
  TrimPswInput,
  TrimTextArea,
  TrimSearch,
  TrimInputNumber,
  MyMessage,
  InfMasonryImgs,
  InfDownLoadModal,
  InfCheckPINModal,
  InfErrorPage,
  PreviewCarouselModal,
  InfoEmptyData,
  InfPhoneCheckModal,
  InfListFooterText,
  SharePopover,
}
