/**
 * Tools是提供全局公用的方法的类
 */
import {
  flow,
  isArray,
  isNil,
  isNumber,
  map,
  pick,
  sum,
  values,
} from 'lodash/fp'

import moment from 'moment'
/* -------------------------------------* /

/**
 *
 * @param {*} url - 匹配的url
 * @param {*} options - 替换对象数组
 *
 * @example
 * getFullRout('path/:id/:type', {id:1, type: 'show'})
 *
 * out -> 'path/1/show'
 */
export const getFullRoute = (url = '', options) => {
  let fullURL = url
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      fullURL = fullURL.replace(`:${key}`, options[key])
    }
  }
  return fullURL
}

/**
 * @param {*} key - 选项中的key值
 * @param {*} feild - 要读取的变量
 * @param {*} option - 选项
 * @param {string} emptyText - 匹配不到时展示
 *
 * @return string - 名称
 *
 * @example:
 *  options = [{key: '1', label: '绿色', color: 'green'}{key: '2', label: '黄色', color: 'yellow'}]
 *  getOption(1, 'color',options)
 *  out -> green
 *  getOption(0, 'color',options, 'no-color')
 *  out -> no-color
 */
export function getOption(key, feild, option, emptyText = '') {
  if (key !== null && key !== undefined) {
    const matched = option.filter((item) => item.key === key)
    return matched[0] ? matched[0][feild] : emptyText
  }
  return ''
}

/**
 *
 * @param {*} keysValue - 匹配键值
 * @param {*} keyName - 匹配键名
 * @param {*} valueName - 获取值名
 * @param {*} option  - 选项
 *
 * @return {sting} array - 获取值数组
 *
 * @example:
 *  options = [{name: 'apple', id: '1', color: 'green'},{name: 'banana', id: '2', color: 'yellow'}]
 *  getValuesByKeys('1,2', 'id', 'name', options)
 *  out -> ['apple', 'banana']
 */
export function getValuesByKeys(keysValue, keyName, valueName, option) {
  const keys = keysValue.split(',')
  let lables = []
  for (var i in option) {
    if (keys.includes(option[i][keyName] + '')) {
      lables.push(option[i][valueName])
    }
  }
  return lables
}

/**
 *
 * @param {*} object
 * @param {*} name
 *
 *
 * @example:
 *  object = {name: 'apple', id: '1'}
 *  initFormFeildValue(object, 'name')
 *  out -> apple
 */
export const initFormFeildValue = (object, name) => {
  return object && !isNil(object[name]) ? object[name] : undefined
}

export const initFormFeildSelectValue = (object, name) => {
  return object && !isNil(object[name])
    ? isNumber(object[name])
      ? object[name]
      : object[name] + ''
    : undefined
}

export const initFormFeildDateValue = (object, name) => {
  return object && !isNil(object[name]) ? moment(object[name]) : undefined
}

export const initFormFeildCascaderLevel3Value = (object, names = []) => {
  return object && isArray(names) && object[names[0]]
    ? flow(pick(names), values)(object)
    : undefined
}

export const dateFormat = (value) => {
  return value.format('YYYY-MM-DD')
}

/**
 *
 * @param {Array[object]} dataSource
 * @param {Array[number]} indexs
 *
 * @example:
 * dataSource = [{name: 'apple'}, {name: 'banana'},{name: 'orange'}]
 * index = [1,2]
 * getDataByIndexs(dataSource, index)
 * out-> = [{name: 'banana'},{name: 'orange'}]
 */
export const getDataByIndexs = (dataSource, indexs) => {
  return dataSource.filter((_, index) => indexs.includes(index + 1))
}

/**
 *
 * @param {Array[object]} dataSource
 * @param {Array[number]} indexs
 *
 * @example:
 * dataSource = [
  { key: 1, label: 'apple' },
  { key: 2, label: 'banana' },
  { key: 3, label: 'orange' },
]
 * indexs = [3,1,2]
 * getDataBySortIndexs(dataSource, indexs)
 * out-> = [{ key: 3, label: 'orange' },
 *          { key: 1, label: 'apple' },
 *          { key: 2, label: 'banana' }]
 */
export const getDataBySortIndexs = (dataSource, indexs) => {
  if (indexs) {
    const newArray = indexs.map((key) => {
      let findIndex = 0
      dataSource.forEach((item, index) => {
        if (item.key === key) {
          findIndex = index
        }
      })
      return { ...dataSource[findIndex] }
    })

    return newArray
  } else return []
}

/**
 *
 * @param {Array[object]} objectArray
 * @param {String} key
 *
 * @example:
 * dataSource = [{id:'1',name: 'banana'},{id: '2',name: 'orange'}]
 * keys = 'id'
 * getDataValueByKey(dataSource, keys)
 * out-> = [1,2]
 */
export const getDataValueByKey = (objectArray, key) => {
  return objectArray.map((item) => item[key])
}

/**
 * @param {*} key - 选项中的key值
 * @param {*} feild - 要读取的变量
 * @param {*} option - 选项
 * @param {string} emptyText - 匹配不到时展示
 *
 * @return string - 名称
 *
 * @example:
 *  options = [{key: '1', label: '绿色', color: 'green'}{key: '2', label: '黄色', color: 'yellow'}]
 *  getOption(1, 'color',options)
 *  out -> green
 *  getOption(0, 'color',options, 'no-color')
 *  out -> no-color
 */
export function getOptionBykeyName(
  keyName,
  key,
  feild,
  option,
  emptyText = ''
) {
  if (key !== null && key !== undefined) {
    const matched = option.filter((item) => item[keyName] === key)
    return matched[0] ? matched[0][feild] : emptyText
  }
  return ''
}

/**
 *
 * 获取页面的 AccessSuffix
 * @example https://name.infotos.co/index => name
 */
export function getAccessSuffix() {
  return window.location.hostname.split('.').shift();
}

export function getDomain() {
  return window.location.hostname;
}

export function setSession(key, value) {
  sessionStorage.setItem(key, value)
}

export function getSession(key) {
  return sessionStorage.getItem(key)
}

export function removeSession(key) {
  sessionStorage.removeItem(key)
}
export function setLocal(key, value) {
  localStorage.setItem(key, value)
}

export function getLocal(key) {
  return localStorage.getItem(key)
}

export function removeLocal(key) {
  localStorage.removeItem(key)
}
