import {Button, Col, Divider, Dropdown, Form, Input, Menu, Popover, Row, Spin, Tooltip} from 'antd'
import BottomMessage from 'components/BottomMessage/index'
import commonComponents from 'components/index'
import download from 'downloadjs'
import Masonry from 'masonry-layout'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import InfiniteScroll from 'react-infinite-scroller'
import styles from './masonryImgs.module.less'
import {isNil} from "lodash";
import {
  getSessionStorageValue,
} from 'utils/Help'

const { PreviewCarouselModal, InfoEmptyData, MyMessage, InfListFooterText } =
  commonComponents
const { TextArea } = Input
export default class MasonryImgs extends React.Component {
  formRef = React.createRef()
  state = {
    imgsData: [],
    hasMore: true,
    contentVisible: false,
    selectedImg: {},
    idOfShowOpts: '',
    previewCarouselModalVisible: false, // 查看图片弹窗
    previewList: [], // 查看弹窗图片list
    previewCurrent: 0, // 查看弹窗当前siderNumber
    pagination: {
      current: 1,
    }, // 分页数据
    loading: true,
    favVisible: false,
  }

  componentDidMount() {
    const { categoryId, callbackObj, setCallbackObj } = this.props
    setCallbackObj({
      ...callbackObj,
      callbackFuncs: {
        ...callbackObj.callbackFuncs,
        ...this,
      },
    })

    if (categoryId.length) {
      this.getCustomorPhotos({ categoryId: categoryId })
    }
  }

  getCustomorPhotos = async ({ pageInfo, ...rest }) => {
    const { _pagePhotos, exHeader, backToLogin } = this.props
    const { imgsData } = this.state
    this.setState({ hasMore: false })
    const res = await _pagePhotos({
      params: {
        ...pageInfo,
        ...rest,
      },
      exHeader,
    })
    if ([0].includes(res.code)) {
      const { pagination, list } = res.data
      const hasMore =
        pagination.current * pagination.pageSize < pagination.total

      const newList = pagination.current === '1' ? list : imgsData.concat(list)

      this.setState({ imgsData: newList, hasMore, pagination, loading: false })
    } else if ([9200].includes(res.code)) {
      backToLogin()
    }
  }

  loadData = (page = 1) => {
    const { hasMore } = this.state

    const { categoryId } = this.props
    if (hasMore) {
      this.getCustomorPhotos({
        categoryId: categoryId,
        pageInfo: { pageNo: page },
      })
    }
  }
  onLoadImg = () => {
    // nowNum++
    // if (nowNum === count) {
    //   // 如果所有图片加载完，调用实现瀑布流代码
    // }

    var elem = document.querySelector('.grid')
    var msnry = new Masonry(elem, {
      // options
      itemSelector: '.grid-item',
    })
    var msnry = new Masonry('.grid', {})
  }

  showOpt = (img) => {
    const { project } = this.props
    const { imgsData } = this.state
    if (
      project.isShowBookmark ||
      project.isSetPhotoHidden ||
      project.canDownload||
      project.isMessage
    ) {
      this.setState({
        idOfShowOpts: img.id,
        previewCurrent: imgsData.findIndex((item) =>
          [img.id].includes(item.id)
        ),
      })
    }
  }
  hideOpt = () => {
    this.setState({ idOfShowOpts: '', favVisible: false })
  }
  // 设置隐藏
  setHide = async ({ img, index }) => {
    let { imgsData } = this.state
    const { exHeader, _setPhototHide, intl } = this.props
    const res = await _setPhototHide({
      data: {
        id: img.id,
        isHide: !img.isHideShow,
      },
      exHeader,
    })
    if (res.data.code == 0) {
      imgsData[index].isHideShow = !img.isHideShow
      this.setState({ imgsData })

      BottomMessage.info(
        img.isHideShow ? intl.formatMessage({ id: 'hasHidden' }) : intl.formatMessage({ id: 'noHidden' }),
        img.thumbInfo['thumb'].photoUrl
      )
    }
    return res
  }
  // 添加到收藏夹
  addToBookmark = async ({ img, index, key }) => {
    let { imgsData } = this.state
    const { exHeader, _addBookmarkPhoto, intl } = this.props
    if (!img.bookmarkIds.includes(key)) {
      const res = await _addBookmarkPhoto({
        data: {
          customerPhotoId: img.id,
          bookmarkIds: [...img.bookmarkIds, key],
          source:'WEB',
        },
        exHeader,
      })
      if (res.data.code == 0) {
        BottomMessage.info(intl.formatMessage({ id: 'addToCollection' }), img.thumbInfo['thumb'].photoUrl)
        imgsData[index].isBookmarkShow = true
        imgsData[index].bookmarkIds = [...img.bookmarkIds, key]
        this.setState({ imgsData })
      }
      return res
    }
  }

  downloadImg = async (img) => {
    const { exHeader, _getCustomerSingleDownloadUrl, project } = this.props
    const { id } = img
    const { downloadCode } = project
    const res = await _getCustomerSingleDownloadUrl({
      data: {
        customerPhotoIds: [id],
        downloadCode:getSessionStorageValue('pin'),
        source:'WEB',
      },
      exHeader,
    })
    if ([0].includes(res.data.code)) {
      //download(res.data.data.packageDownloadUrl)
      let data = res.data.data;
      if(data.length > 0){
        // download(data[0].photoUrl)
        let request=new XMLHttpRequest();
        request.open("GET", data[0].photoUrl, true);
        request.responseType="blob";
        request.onload= function(e){
          download(e.target.response, data[0].photoName);
        };
        request.send();
      }
    }
    return res
  }

  menuFeild = ({ img, index }) => {
    const { favoriteList, checkIdentity } = this.props
    return (
      <div className={styles.favMenu}>
        <Menu
          onClick={({ item, key, keyPath, domEvent }) => {
            domEvent.stopPropagation()
            checkIdentity({
              funcName: 'addToBookmark',
              options: {
                img,
                index,
                key,
              },
            })
          }}
        >
          {favoriteList.map((favorite) => {
            return (
              <Menu.Item key={favorite.id} className={styles.menuFeild}>
                {favorite.name}
                <svg className={`icon ${styles.imgIconMin}`} aria-hidden="true">
                  {img.bookmarkIds.includes(favorite.id) ? (
                    <use xlinkHref="#iconbtn_fav_on"></use>
                  ) : (
                    <use xlinkHref="#iconbtn_fav1"></use>
                  )}
                </svg>
              </Menu.Item>
            )
          })}
        </Menu>
      </div>
    )
  }
  // 显示查看弹窗
  showPreviewCarouselModal = async (id) => {
    const { imgsData } = this.state
    await this.setState({
      previewCurrent: imgsData.findIndex((item) => [id].includes(item.id)),
    })
    await this.setState({
      previewCarouselModalVisible: true,
    })
  }
  // 隐藏查看弹窗
  hidePreviewCarouselModal = (newList) => {
    this.setState({
      previewCarouselModalVisible: false,
      previewCurrent: 0,
      imgsData: newList,
    })
  }
  setPreviewCurrent = async (index) => {
    await this.setState({
      previewCurrent: index,
    })
  }
  loadNext = async () => {
    const { categoryId, _pagePhotos, exHeader } = this.props
    const { pagination, imgsData } = this.state
    const res = await _pagePhotos({
      params: {
        ...pagination,
        pageNo: Number(pagination.current) + 1,
        categoryId: categoryId,
      },
      exHeader,
    })
    let newList = []
    if ([0].includes(res.code)) {
      const { pagination, list } = res.data
      const hasMore =
        pagination.current * pagination.pageSize < pagination.total
      newList = list
      this.setState({ hasMore, pagination })
    }
    return newList
  }

  onVisibleChange = (visible) => {
    this.setState({ favVisible: visible })
  }
  //留言
  clickPopover = ({ visible, item, key }) => {
    this.setState({
      contentVisible: visible,
      selectedImg: { img: item, index: key },
    })
  }
  clickPopover = ({ visible, item, key }) => {
    this.setState({
      contentVisible: visible,
      selectedImg: { img: item, index: key },
    })
  }
  // 删除收藏夹评论
  deleteMessage = async ({ item = {}, key }) => {
    const { selectedImg, imgsData } = this.state
    const { exHeader, _saveMessage } = this.props
    const { img, index } = selectedImg
    const body = {
      id: item.id || img.id,
      message: '',
    }
    if (!key&&key!=0) {
      this.formRef.current.resetFields()
    }
    const res = await _saveMessage({ data: body, exHeader })

    if (res.data.code == 0) {
      const inKey = isNil(index) ? key : index
      imgsData[inKey].hasMessage = false
      imgsData[inKey].message = ''
      this.setState({ imgsData, contentVisible: false, selectedImg: {} })
    }
    return res
  }

  cancel = () => {
    this.setState({ contentVisible: false, selectedImg: {} })
    this.formRef.current.resetFields()
  }
  // 保存收藏夹评论
  saveMessage = async ({ message, item = {}, key }) => {
    const { selectedImg, imgsData } = this.state
    const { exHeader, _saveMessage } = this.props
    const { img, index } = selectedImg
    const body = {
      id: item.id || img.id,
      message: message,
    }
    const res = await _saveMessage({ data: body, exHeader })
    if (res.data.code == 0) {
      const inKey = isNil(index) ? key : index
      imgsData[inKey].hasMessage = true
      imgsData[inKey].message = message
      this.setState({
        imgsData,
        contentVisible: false,
        selectedImg: {},
      })
    }
    return res
  }
  getContent = (item) => {
    const { checkIdentity, intl } = this.props
    return (
        <Form
            key={this.state.loading}
            onFinish={(values) =>
                checkIdentity({
                  funcName: 'saveMessage',
                  options: values,
                })
            }
            initialValues={{ message: item.message }}
            ref={this.formRef}
        >
          <Form.Item
              noStyle
              name="message"
              rules={[{ max: 100, message: intl.formatMessage({ id: 'max100'})}]}
          >
            <TextArea
                placeholder={intl.formatMessage({ id: 'remarkMax100'})}
                rows={3}
                bordered={false}
                style={{ resize: 'none', width: 260 }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                maxLength={100}
            />
          </Form.Item>
          <div className={styles.messageBtn}>
            <a
                onClick={(e) => {
                  e.stopPropagation()
                  checkIdentity({
                    funcName: 'deleteMessage',
                  })
                }}
                type="link"
                className="dange-default-btn"
                style={{ visibility: item.message.length > 0 ? '' : 'hidden' }}
            >
              {intl.formatMessage({ id: 'delete' })}
            </a>

            <div>
              <Button
                  type="link"
                  onClick={(e) => {
                    e.stopPropagation()
                    this.cancel()
                  }}
                  className="link-btn-default"
              >
                {intl.formatMessage({ id: 'cancel' })}
              </Button>
              <Button
                  type="primary"
                  htmlType="submit"
                  size="small"
                  className="Inf_black_btn"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
              >
                {intl.formatMessage({ id: 'save' })}
              </Button>
            </div>
          </div>
        </Form>
    )
  }
  render() {
    const {
      imgsData,
      hasMore,
      idOfShowOpts,
      contentVisible,
      selectedImg,
      previewCarouselModalVisible,
      previewCurrent,
      pagination,
      loading,
      favVisible,
    } = this.state
    const { checkIdentity, project, favoriteList, canDownload = 'N' } = this.props
    return <>
      {<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Spin spinning={loading} tips={<FormattedMessage id="getData" />} />
        </div>}
      {!loading && !hasMore && !imgsData.length && (
        <div style={{ marginTop: 120 }}>
          <InfoEmptyData description={<FormattedMessage id="categoryNoData" />} />
        </div>
      )}
     {(!loading  && (hasMore || imgsData.length) > 0 &&
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        // useWindow={false}
        hasMore={hasMore}
        loadMore={this.loadData}
      >
          <Row className="grid fixAntdAndMosonryGrid" gutter={[10, 10]}>
            {imgsData.map((item, key) => {
              return (
                <Col
                  className="grid-item"
                  key={key}
                  onMouseEnter={this.showOpt.bind(this, item)}
                  onMouseLeave={this.hideOpt}
                  onClick={() => this.showPreviewCarouselModal(item.id)}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                  // xxl={4}
                >
                  <div className={styles.photoList}>
                  <img
                    className="masonry-img"
                    src={item.thumbInfo['general'].photoUrl}
                    key={item.thumbInfo['general'].photoKey}
                    onLoad={(e) => {
                      e.stopPropagation()
                      this.onLoadImg()
                    }}
                  />
                  <div className={styles.imgTop}>
                    <div className={styles.imgTopLeft}>
                      {item.isBookmarkShow && project.isShowBookmark && (
                        <span
                          className={`iconfont iconicon_favoried ${styles.imgIcon}`}
                        ></span>
                        // <svg
                        //   className={`icon ${styles.imgIcon}`}
                        //   aria-hidden="true"
                        // >
                        //   <use xlinkHref="#iconicon_favoried"></use>
                        // </svg>
                      )}
                      {item.isHideShow && project.isSetPhotoHidden ? (
                        // <svg
                        //   className={`icon ${styles.imgIcon}`}
                        //   aria-hidden="true"
                        // >
                        //   <use xlinkHref="#iconicon_hidden"></use>
                        // </svg>
                        <span
                          className={`iconfont iconicon_hidden ${styles.imgIcon}`}
                        ></span>
                      ) : null}

                      {item.message && project.isMessage && sessionStorage.getItem('accessType')=='clientMode'  ? (
                          <span
                              className={`iconfont iconicon_commented ${styles.imgIcon}`}
                          ></span>
                      ) : null}
                    </div>
                    <div className={styles.imgTopRight}>
                      {item.likeMarkCount>1?(
                      <span className={`iconfont iconico_favmarked_s ${styles.imgIcon}`}><span className={styles.count}>{item.likeMarkCount}</span></span>
                      ):''}
                    </div>
                  </div>
                    {(project.isShowBookmark||
                        (project.isSetPhotoHidden && sessionStorage.getItem('accessType') == 'clientMode')||
                        (project.isMessage && sessionStorage.getItem('accessType') == 'clientMode')||
                        (project.canDownload && canDownload && (sessionStorage.getItem('accessType') == 'clientMode' || (sessionStorage.getItem('accessType') == 'visitorMode' && project.isVisitorDownload == 'Y')) )||
                        (project.isShowBookmark || (project.isSetPhotoHidden && sessionStorage.getItem('accessType') == 'clientMode'))
                    )&&(
                      <div
                          className={styles.imgBottom}
                          style={{
                            display: idOfShowOpts === item.id ? '' : 'none',
                          }}
                      >
                        <div className={styles.imgBtns}>
                          {project.isShowBookmark && (
                              <span>
                                {favoriteList.length > 1 ? (
                                    <Dropdown
                                        overlay={this.menuFeild({
                                          img: item,
                                          index: key,
                                        })}
                                        trigger="click"
                                        onVisibleChange={this.onVisibleChange}
                                        visible={favVisible && idOfShowOpts === item.id}
                                    >
                                      <a
                                          onClick={(e) => {
                                            e.stopPropagation()
                                          }}
                                      >
                                        <svg
                                            className={`icon ${styles.imgIcon}`}
                                            aria-hidden="true"
                                        >
                                          {item.isBookmarkShow ? (
                                              <use xlinkHref="#iconbtn_fav_on"></use>
                                          ) : (
                                              <use xlinkHref="#iconbtn_fav1"></use>
                                          )}
                                        </svg>
                                      </a>
                                    </Dropdown>
                                ) : (
                                    <a
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          checkIdentity({
                                            funcName: 'addToBookmark',
                                            options: {
                                              img: item,
                                              index: key,
                                              key: favoriteList[0].id,
                                            },
                                          })
                                        }}
                                    >
                                      <svg
                                          className={`icon ${styles.imgIcon}`}
                                          aria-hidden="true"
                                      >
                                        {item.isBookmarkShow ? (
                                            // <Tooltip
                                            // //  title={intl.formatMessage({ id: "lookCollection"})}
                                            //  title="取消收藏"
                                            //  placement="bottom"
                                            //  overlayClassName="no-arrow"
                                            // >
                                            <use xlinkHref="#iconbtn_fav_on"></use>
                                            // </Tooltip>
                                        ) : (
                                            // <Tooltip
                                            // //  title={intl.formatMessage({ id: "lookCollection"})}
                                            // title="收藏"
                                            // placement="bottom"
                                            // overlayClassName="no-arrow"
                                            // >
                                            <use xlinkHref="#iconbtn_fav1"></use>
                                            // </Tooltip>
                                        )}
                                      </svg>
                                    </a>
                                )}
                              </span>
                          )}
                          {project.isSetPhotoHidden && sessionStorage.getItem('accessType') == 'clientMode' && (
                              <span>
                              {project.isShowBookmark && (<Divider type="vertical"/>)}
                                <a
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      checkIdentity({
                                        funcName: 'setHide',
                                        options: {
                                          img: item,
                                          index: key,
                                        },
                                      })
                                    }}
                                >
                            <svg
                                className={`icon ${styles.imgIcon}`}
                                aria-hidden="true"
                            >
                              {item.isHideShow ? (
                                  // <Tooltip
                                  // //  title={intl.formatMessage({ id: "lookCollection"})}
                                  //  title="取消私有"
                                  //  placement="bottom"
                                  //  overlayClassName="no-arrow"
                                  // >
                                  <use xlinkHref="#iconbtn_hidden_on"></use>
                                  // </Tooltip>
                              ) : (
                                  // <Tooltip
                                  // //  title={intl.formatMessage({ id: "lookCollection"})}
                                  //  title="设为私有"
                                  //  placement="bottom"
                                  //  overlayClassName="no-arrow"
                                  // >
                                  <use xlinkHref="#iconbtn_hidden1"></use>
                                  // </Tooltip>
                              )}
                            </svg>
                          </a>
                        </span>
                          )}
                          {(project.isMessage && sessionStorage.getItem('accessType') == 'clientMode' )&& (
                              <span>
                              {(project.isShowBookmark||(project.isSetPhotoHidden && sessionStorage.getItem('accessType') == 'clientMode')) && (
                                  <Divider type="vertical"/>)}
                            <Popover
                                placement="bottomLeft"
                                content={this.getContent.bind(this, item)}
                                trigger="click"
                                overlayClassName="messagePopover noArrow"
                                visible={contentVisible && selectedImg.img == item}
                                onVisibleChange={(visible) =>
                                    this.clickPopover({visible, item, key})
                                }
                                destroyTooltipOnHide={true}
                            >
                              <a
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}
                              >
                                <span
                                    className={item.message ? `iconfont iconbtn_comment_on ${styles.imgIcon} ${styles.imgIcon2}` : `iconfont iconbtn_comment ${styles.imgIcon}`}
                                ></span>
                              </a>
                            </Popover>
                          </span>
                          )}
                          {project.canDownload && canDownload && (sessionStorage.getItem('accessType') == 'clientMode' || (sessionStorage.getItem('accessType') == 'visitorMode' && project.isVisitorDownload == 'Y')) && (
                              // <Tooltip
                              //   //  title={intl.formatMessage({ id: "lookCollection"})}
                              //   title="下载"
                              //   placement="bottom"
                              //   overlayClassName="no-arrow"
                              // >
                              <span>
                              {(project.isShowBookmark || (project.isSetPhotoHidden && sessionStorage.getItem('accessType') == 'clientMode')||(project.isMessage && sessionStorage.getItem('accessType') == 'clientMode' )) && (
                                  <Divider type="vertical"/>)}
                                <a
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      checkIdentity({
                                        type: 'download',
                                        funcName: 'downloadImg',
                                        options: item,
                                      })
                                    }}
                                >
                            <span
                                className={`iconfont iconbtn_download1 ${styles.imgIcon}`}
                            ></span>
                          </a>
                        </span>
                              // </Tooltip>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              )
            })}
            {/* 列表页尾提醒文字 */}
            <InfListFooterText hasMore={hasMore} pagination={pagination} />
          </Row>
        {/* 查看弹窗 */}
        <PreviewCarouselModal
          visible={previewCarouselModalVisible}
          onCancel={this.hidePreviewCarouselModal}
          list={imgsData}
          key={imgsData}
          previewCurrent={previewCurrent}
          downloadImg={(data) => {
            checkIdentity({
              type: 'download',
              funcName: 'downloadImg',
              options: data,
            })
          }}
          setPicHide={(data) =>
            checkIdentity({
              funcName: 'setHide',
              options: data,
            })
          }
          saveMessage={(data) =>
              checkIdentity({
                funcName: 'saveMessage',
                options: data,
              })
          }
          cancelBookmark={(data) =>
              checkIdentity({
                funcName: 'cancelBookmark',
                options: data,
              })
          }
          deleteMessage={(data) =>
              checkIdentity({
                funcName: 'deleteMessage',
                options: data,
              })
          }
          menuFeild={this.menuFeild}
          pagination={pagination}
          hasMore={hasMore}
          showOpt={true}
          setPreviewCurrent={this.setPreviewCurrent}
          loadData={this.loadNext}
          showKey="photoName"
          project={project}
          checkIdentity={checkIdentity}
          favoriteList={favoriteList}
          addToBookmark={this.addToBookmark}
          canDownload={canDownload}
        />
      </InfiniteScroll>
    )}
  </>
  }
}
