import React, { useEffect, useState } from 'react'
import './App.less'

import Mock from 'mock/index'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import thunk from 'redux-thunk'
// import { useParams } from 'react-router-dom'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import promiseMiddleware from 'redux-promise'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import { split, compact, flow, last, dropRight, take, includes } from 'lodash/fp'

// request

import {
  getCommonSetting as _getCommonSetting,
  getWebsiteSetting as _getWebsiteSetting,
} from 'reduxModules/commonSetting'
import { getAccessSuffix ,getDomain} from 'utils/Tools'
import { getAll as _getShowCaseSetting } from 'reduxModules/showcaseSetting'
import { getProjectInfoForLogin as _getProjectInfoForLogin
  // ,getProjectById as _getProjectById
} from 'reduxModules/customerPhoto'
// 引入 reducer
import RootReducer from 'reduxs/rootReducer'
import { PAGE_PATH } from 'apis/Router'

// 引入page

import CustomerVisit from 'pages/CustomerVisit/Containers/Index'
import Dashboard from 'pages/Dashboard/Containers/Index'
import CategoryPhotosPage from 'pages/CategoryPhotosPage/Containers/Index'
import FavoritePage from 'pages/Favorite/Containers/Index'
import SharePhotosPage from 'pages/SharePhotosPage/Containers/Index'
import WxCodePage from 'pages/WxCodePage/Containers/Index'
// import ShareFavorite from 'pages/ShareFavorite/Containers/Index'
// 打包下载
import PackDownloadPage from 'pages/PackDownload/Containers/Index'
import zh_CN from 'assets/locale/zh_CN'
import en_US from 'assets/locale/en_US'
import zh_HK from 'assets/locale/zh_HK'
import zh_TW from 'assets/locale/zh_TW'
import ja_JP from 'assets/locale/ja_JP'
import ko_KR from 'assets/locale/ko_KR'
import antdEn from 'antd/lib/locale-provider/en_US'
import antdZh from 'antd/lib/locale-provider/zh_CN'
import antdHk from 'antd/lib/locale-provider/zh_HK'
import antdTw from 'antd/lib/locale-provider/zh_TW'
import antdJa from 'antd/lib/locale-provider/ja_JP'
import antdKo from 'antd/lib/locale-provider/ko_KR'

import Imgs from 'imgs'
import './App.less'
import download from 'downloadjs'

// console.log(process.env.NODE_ENV + ':' + process.env.REACT_APP_ENV)

// 引入mock模块
if (process.env.REACT_APP_ENV === 'mock') {
  Mock.start() //并且执行初始化函数
}
// 引入mock模块end
const store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(thunk, promiseMiddleware))
)
const CommonRequest = () => {
  const dispatch = useDispatch()
  const commonSetting = useSelector((state) => state.commonSetting)
  useEffect(() => {
    dispatch(_getCommonSetting())
    dispatch(_getShowCaseSetting())
  }, [])
  return [9300].includes(commonSetting.code) ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div>
        <div style={{ margin: 20 }}>
          <img src={Imgs.LogoBK} />
        </div>
        <div style={{ textAlign: 'center' }}>{commonSetting.errorMessage}</div>
      </div>
    </div>
  ) : (
    <></>
  )
}

const NotMatch = () => {
  return <></>
}
const App = () => {
  const [appLocale, changeAppLocale] = useState({
    messages: {
      ...zh_CN,
    },
    antd: antdZh,
    locale: 'zh-CN',
  })
  const [pathname, setPathname] = useState(window.location.pathname)
  const domain = getDomain()
  // const domain = 'ucdworks.infotos.co';
  const pathArr = flow(
    split('/'),
    compact,
    take(3),
  )(window.location.pathname)

  let projectId = last(pathArr);
  let path = flow(
    dropRight(1),
    last,
  )(pathArr)
  if (includes('download')(pathArr)) {
    projectId = flow(
      dropRight(1),
      last,
    )(pathArr)
    path = 'download'
  }
  const projectPages = [
    'collection',
    'c',
    'favorite',
    'sharePhotos',
    // 'shareFavorite',
    'download',
  ]
  useEffect(() => {
    if (projectId && includes(path)(projectPages)) {
      const exHeader = {
        projectId,
      }
      const getProjectInfo =  async () => {
        const res = await _getProjectInfoForLogin({
          params: {
            id: projectId,
          },
          exHeader,
        })
        getLocale(res.data.language)
      }
      getProjectInfo()
    } else {
      const getWebsite = async () => {
        const res = await _getWebsiteSetting({
          domain,
        // const res = await _getProjectById({
        //   params: {
        //     id: projectId,
        //   },
        })
        getLocale(res.data.data)
        // getLocale(res.data.language)
      }
      getWebsite();
    }

  }, [pathname]);

  const getLocale = (str) => {
    if (str === 'CN') {
      return changeAppLocale({
        messages: {
          ...zh_CN,
        },
        antd: antdZh,
        locale: 'zh-CN',
      })
    }
    if (str === 'HK') {
      return changeAppLocale({
        messages: {
          ...zh_HK,
        },
        antd: antdHk,
        locale: 'zh-HK',
      })
    }
    if (str === 'TW') {
      return changeAppLocale({
        messages: {
          ...zh_TW,
        },
        antd: antdTw,
        locale: 'zh-TW',
      })
    }
    if (str === 'JP') {
      return changeAppLocale({
        messages: {
          ...ja_JP,
        },
        antd: antdJa,
        locale: 'ja-JP',
      })
    }
    if (str === 'KR') {
      return changeAppLocale({
        messages: {
          ...ko_KR,
        },
        antd: antdKo,
        locale: 'ko-KR',
      })
    }
    return changeAppLocale({
      messages: {
        ...en_US,
      },
      antd: antdEn,
      locale: 'en-US',
    })
  }
  // console.log('---->', appLocale);
  return (
    <div className="App">
      <Provider store={store}>
        <ConfigProvider locale={appLocale.antd} autoInsertSpaceInButton={false}>
          <IntlProvider
            locale={appLocale.locale}
            messages={appLocale.messages}
            key={appLocale.locale}
          >
            <CommonRequest />
            <NotMatch />
            <Router>
              <Switch>
                {/* 获取微信信息 */}
                <Route path={PAGE_PATH.wxcode} render={(routeProps) => {
                  setPathname(window.location.pathname)
                return <WxCodePage {...routeProps} />}} />
                {/* 打包下载 */}
                <Route path={PAGE_PATH.download} render={(routeProps) => {
                  setPathname(window.location.pathname)
                return <PackDownloadPage {...routeProps} />}} />
                {/* 客片分类访问 */}
                <Route path={PAGE_PATH.customerVisit} render={(routeProps) => {
                  setPathname(window.location.pathname)
                return <CustomerVisit {...routeProps} />}} />
                <Route
                  path={PAGE_PATH.projectDetail}
                  render={(routeProps) => {
                    setPathname(window.location.pathname)
                  return <CategoryPhotosPage {...routeProps} />}}
                />
                <Route path={PAGE_PATH.favorite} render={(routeProps) => {
                    setPathname(window.location.pathname)
                  return <FavoritePage {...routeProps} />}} />
                <Route path={PAGE_PATH.sharePhotos} render={(routeProps) => {
                    setPathname(window.location.pathname)
                  return <SharePhotosPage {...routeProps} />}} />
                {/* <Route path={PAGE_PATH.shareFavorite} render={(routeProps) => {
                    setPathname(window.location.pathname)
                  return <ShareFavorite {...routeProps} />}} /> */}
                <Route path={PAGE_PATH.home} render={(routeProps) => {
                    setPathname(window.location.pathname)
                  return <Dashboard {...routeProps} />}} />
                <Redirect to={PAGE_PATH.home} />
              </Switch>
            </Router>
            </IntlProvider>
        </ConfigProvider>
      </Provider>
    </div>
  )
}

export default App
