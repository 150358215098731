/*相册图片页面*/
import { Affix, Dropdown, Layout, Menu, Tooltip } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import commonComponents from 'components/index'
import { ProjectStatus } from 'constants/Options'
import { isEmpty } from 'lodash/fp'
import React, { useEffect, useState,useRef } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom'
import { find } from 'lodash/fp'
import {
  addBookmarkPhoto as _addBookmarkPhoto,
  getCustomerSingleDownloadUrl as _getCustomerSingleDownloadUrl,
  getFavoriteList as _getFavoriteList,
  saveCustomerMessage as _saveCustomerMessage,
  getPhotoCategory as _getPhotoCategory,
  getProjectById as _getProjectById,
  pageCustomerPhoto as _pageCustomerPhoto,
  setOpenShare as _setOpenShare,
  setOpenSharePassword as _setOpenSharePassword,
  setPhototHide as _setPhototHide,
  addShare as _addShare,
  verifyDownloadCode as _verifyDownloadCode,
} from 'reduxModules/customerPhoto'
import { login as _login,
  record as _record, } from 'reduxModules/member'
import {
  getSessionStorageValue,
  removeSessionStorageByKey,
  setSessionStorageByKey,
} from 'utils/Help'
import { removeToken, setToken } from 'utils/Request'
import { getFullRoute, removeSession } from 'utils/Tools'
import AboutModal from '../Components/AboutModal'
import MasonryImgs from '../Components/MasonryImgs'
import styles from './index.module.less'

const {
  InfDownLoadModal,
  InfPhoneCheckModal,
  InfoEmptyData,
  SharePopover,
    CommonFooter,
} = commonComponents
const { SubMenu } = Menu
const { Footer } = Layout
const CategoryPhotosPage = (props) => {
  let history = useHistory()
  let { id } = useParams()
  const intl = useIntl();
  const exHeader = {
    projectId: id,
  }
  const commonSettingData = useSelector((state) => state.commonSetting.data)

  const showcase = useSelector((state) => state.showcaseSetting.data)
  // const [imgsData, setImgsData] = useState([])
  const [imgsData, setImgsData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [shareFormVisible, setShareFormVisible] = useState(false)
  const [aboutVisible, setAboutVisible] = useState(false)
  const [currentCategory, setCurrentCategory] = useState(false)

  const [photoCategorys, setPhotoCategorys] = useState([])
  const [favoriteList, setFavoriteList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  // 下载登录
  const [downloadModalVisible, setDownloadModalVisible] = useState(false)

  // 客户验证
  const [customerModalVisible, setCustomerModalVisible] = useState(false)

  // 弹窗的type
  const [modalType, setModalType] = useState('')
  // 项目详情
  const [project, setProject] = useState({})
  // 项目详情
  const [accessType, setAccessType] = useState('')
  //isDownLodaCode 验证码是否正确
  const [isDownLodaCode, setIsDownLodaCode] = useState(false)
  const [loading, setLoading] = useState(false)

  // 验证的方法对象
  const [callbackObj, setCallbackObj] = useState({
    callbackFuncs: {},
    callbackName: '',
    callbackOptions: {},
  })

  const callbackName = useRef(null);
  const callbackOptions = useRef(null);
  useEffect(() => {
    getPhotoCategory()
    getProjectData()
    getFavoriteList()
    // addRecord()
    setAccessType(sessionStorage.getItem('accessType'))
  }, [])
  //添加记录
  const addRecord=async ()=>{
    const res = await _record({
      data: {
        id: id,
      },
      exHeader,
    })
    if (res.code == 0) {

    }
  }
  const getProjectData = async () => {
    const res = await _getProjectById({
      params: {
        id: id,
      },
      exHeader,
    })
    if (res.code == 0) {
      document.title = `${res.data.name} - ${commonSettingData.websiteTitle}`
      setProject(res.data)
      getVerifyCode(res.data.id)
    }
  }
  //若已有验证码则判定当前验证码
  const getVerifyCode=async(id)=>{
    if(!!getSessionStorageValue('pin')){
      const res = await _verifyDownloadCode({
        data: {
          code:getSessionStorageValue('pin'),
          projectId:id
        },
        exHeader,
      })
      if (res.code == 0&&res.data) {
        setIsDownLodaCode(true)
      }
    }
  }

  const getPhotoCategory = async () => {
    setLoading(false)
    const res = await _getPhotoCategory({
      exHeader,
    })
    if (res.code == 0) {
      setPhotoCategorys(res.data)

      if (isEmpty(selectedCategory) && res.data[0]) {
        setSelectedCategory(res.data[0].id.toString())
        setCurrentCategory(res.data[0].canDownload === 'Y');
      }
      setLoading(true)
    } else if ([9200].includes(res.code)) {
      backToLogin()
      setLoading(true)
    }
  }
  const backToLogin = () => {
    history.push(
      getFullRoute(PAGE_PATH.customerVisit, {
        id: id,
      })
    )
  }
  const getFavoriteList = async () => {
    const res = await _getFavoriteList({
      exHeader,
    })
    if (res.code == 0) {
      setFavoriteList(res.data)
    }
  }

  // 切换按钮
  const changeMenu = ({ key }) => {
    setSelectedCategory(key)
    const currentCategory = find(({ id }) => id === key)(photoCategorys);
    setCurrentCategory(currentCategory.canDownload === 'Y');
  }

  const showShareVisible = () => {
    setShareFormVisible(true)
  }

  // 跳转到打包下载页面
  const gotoPackDownload = () => {
    // 1. 获取当前的验证信息
    const token = getSessionStorageValue('token')
    //  2. 获取当前项目是否公开
    const { isLock } = project
    if (!isLock && isEmpty(token)) {
      setCallbackObj({
        ...callbackObj,
        callbackName: 'gotoPackDownload',
        callbackOptions: {},
      })
      setCustomerModalVisible(true)
    } else {
      history.push(
        getFullRoute(PAGE_PATH.download, {
          id: id,
          type: window.btoa('customer'),
        })
      )
    }
  }

  // 跳转到收藏页面
  const gotoFavorite = () => {
    // 1. 获取当前的验证信息
    const token = getSessionStorageValue('token')
    //  2. 获取当前项目是否公开
    const { isLock } = project

    if (!isLock && isEmpty(token)) {
      setCallbackObj({
        ...callbackObj,
        callbackName: 'gotoFavorite',
        callbackOptions: {},
      })
      setCustomerModalVisible(true)
    } else {
      history.push(
        getFullRoute(PAGE_PATH.favorite, {
          id: id,
        })
      )
    }
  }

  // 下载登录
  const downloadLogin = async (value, form) => {
    const { callbackFuncs, callbackName, callbackOptions } = callbackObj
    // // 如果下载验证码已验证通过过
    // if(isDownLodaCode){
    //   return setTimeout(() => {
    //     callbackFuncs[callbackName](callbackOptions)
    //   }, 1000)
    //   return
    // }
    //判定验证码是否正确
    const res = await _verifyDownloadCode({
      data: {
        code:value.code,
        projectId:project.id
      },
      exHeader,
    })
    console.log(getSessionStorageValue('pin'))
    //验证码是判定否正确且未验证过
    if (res.code == 0&&res.data) {
      setIsDownLodaCode(true)
      setDownloadModalVisible(false)
      setSessionStorageByKey('pin', value.code)
      form.resetFields()
      return setTimeout(() => {
        callbackFuncs[callbackName](callbackOptions)
      }, 1000)
    }else{
      form.setFields([
        {
          name: 'code',
          value: value.code,
          errors: [intl.formatMessage({ id: 'codeNotRight' })],
        },
      ])
    }
    return
    const downloadCode = project.downloadCode
    const canView = downloadCode === value.code
    // const { callbackFuncs, callbackName, callbackOptions } = callbackObj
    if (canView) {
      setDownloadModalVisible(false)
      setSessionStorageByKey('pin', value.code)
      form.resetFields()
      return setTimeout(() => {
        callbackFuncs[callbackName](callbackOptions)
      }, 1000)
    } else {
      form.setFields([
        {
          name: 'code',
          value: value.code,
          errors: [intl.formatMessage({ id: 'codeNotRight' })],
        },
      ])
    }
  }

  // 验证登陆状态
  const checkIdentity = async ({ type = '', funcName = '', options = {} }) => {
    const isDownloadType = ['packDownload', 'download'].includes(type)
    // 1. 获取当前的验证信息
    const pin = getSessionStorageValue('pin')
    //  2. 获取当前项目是否公开 是否下载需要验证码
    const { isLock, isDownloadCode, downloadCode } = project
    const { callbackFuncs } = callbackObj
    setCallbackObj({
      ...callbackObj,
      callbackName: funcName,
      callbackOptions: options,
    })
    // 是否验证了 pin 码 （用于需要 pin 码验证的情况）
    const isCheckPin = [downloadCode].includes(pin)

    if (isDownloadType && isDownloadCode && !isCheckPin) {
      // 是下载操作 如果需要下载验证 且客户未验证过 显示下载登录弹窗
      //若验证码已验证且正确则直接下载否则显示弹出框
      if(isDownLodaCode){
        // callbackName.current=funcName
        // callbackOptions.current=options
        return setTimeout(() => {
          callbackFuncs[funcName](options)
        }, 1000)
      }else {
        setDownloadModalVisible(true)
      }
    } else {
      // 派发请求 看登录是否过期
      const res = await callbackFuncs[funcName](options)

      if (!isLock && res && res.data && [9403, 9200].includes(res.data.code)) {
        // 如果处于公开 且客户未验证过 显示客户验证弹窗
        setCustomerModalVisible(true)
      }
    }
  }
  // 退出
  const loginOut = () => {
    // 删除用户登录的token
    removeToken()
    removeSession('loginUser')
    // 删除pin
    removeSessionStorageByKey('pin')
    // 跳转回客片登录页
    history.push(
      getFullRoute(PAGE_PATH.customerVisit, {
        id: id,
      })
    )
  }
  // 验证后可调用的回调集
  const funcs = {
    gotoPackDownload: gotoPackDownload,
    showShareVisible: showShareVisible,
    gotoFavorite: gotoFavorite,
    // gotoPackDownload: gotoPackDownload,
  }
  // 验证手机号或email
  const checkPhone = async (value, form) => {
    const { callbackFuncs, callbackName, callbackOptions } = callbackObj

    const res = await _login({
      data: {
        account: value.code,
      },
      exHeader,
    })

    if ([0].includes(res.data.code)) {
      const { token, customerName, customerPhone } = res.data.data
      await setToken(token)

      await setSessionStorageByKey(
        'loginUser',
        JSON.stringify({ customerName, customerPhone })
      )
      setCustomerModalVisible(false)
      form.resetFields()

      isEmpty(callbackFuncs)
        ? funcs[callbackName](callbackOptions)
        : callbackFuncs[callbackName](callbackOptions)
    } else {
      form.setFields([
        {
          name: 'code',
          value: value.code,
          errors: [intl.formatMessage({ id: 'inputNoRight' })],
        },
      ])
    }
  }
  useEffect(() => {
    setCallbackObj({
      ...callbackObj,
      callbackFuncs: {
        ...callbackObj.callbackFuncs,
        ...funcs,
      },
    })
  }, [])
  const showAbout = () => {
    setAboutVisible(true)
  }

  // 关于用户
  const userMenu = () => {
    const token = getSessionStorageValue('token')
    return (
      <div className={styles.aboutMenu}>
        <Menu>
          <Menu.Item>
            <a onClick={showAbout}>{intl.formatMessage({ id: "about"})}</a>
          </Menu.Item>
          <Menu.Divider style={{ display: isEmpty(token) ? 'none' : '' }} />
          <Menu.Item style={{ display: isEmpty(token) ? 'none' : '' }}>
            <a onClick={loginOut}>
              {intl.formatMessage({ id: "quit"})}
            </a>
          </Menu.Item>
        </Menu>
      </div>
    )
  }
  return (
    <div className={styles.custphotoLayout}>
      <Layout>
        {(ProjectStatus[1].key === project.status&&loading) ? (
          <>
            <div className={styles.header}>
              <div className={styles.topInfo}>
                <div>
                  <div className={styles.projectName}>{project.name}</div>
                  <div className={styles.projectDate}>
                    {project.shootingDay=='Invalid date'?'':project.shootingDay}
                  </div>
                  <div className={styles.logo}>
                    <Link to={PAGE_PATH.home}>
                      <img
                        src={
                          isEmpty(commonSettingData)
                            ? ''
                            : commonSettingData.customerLogoPhotoInfo.photoUrl
                        }
                      />
                    </Link>
                  </div>
                </div>
              </div>
              {photoCategorys.length > 0 ? (
                <Affix>
                  <div className={styles.topArea}>
                    <div className={styles.category}>
                      <Menu
                        mode="horizontal"
                        className={styles.Inf_menus}
                        onClick={changeMenu}
                        selectedKeys={[selectedCategory]}
                      >
                        {photoCategorys.map((category) => (
                          <Menu.Item key={category.id}>
                            {category.name}
                          </Menu.Item>
                        ))}
                      </Menu>
                    </div>
                    <div className={styles.iconBtns}>
                      {project.isShowBookmark && (
                        <Tooltip
                          placement="bottom"
                          title={intl.formatMessage({ id: "lookCollection"})}
                          overlayClassName="no-arrow"
                        >
                          <a>
                            <span
                              onClick={gotoFavorite}
                              className="iconfont iconbtn_favorites"
                            ></span>
                          </a>
                        </Tooltip>
                      )}

                      {project.canDownload && (sessionStorage.getItem('accessType')=='clientMode'||(sessionStorage.getItem('accessType')=='visitorMode' && project.isVisitorDownload=='Y')) && (
                        <Tooltip
                          placement="bottom"
                          title={intl.formatMessage({ id: 'downLoadAllPic' })}
                          overlayClassName="no-arrow"
                        >
                          <a>
                            <span
                              onClick={() =>
                                checkIdentity({
                                  type: 'packDownload',
                                  funcName: 'gotoPackDownload',
                                })
                              }
                              className="iconfont iconbtn_downloadall"
                            ></span>
                          </a>
                        </Tooltip>
                      )}
                      {project.isAllowShare && (
                        <SharePopover
                          shareDetail={{
                            shareUrl: project.shareUrl,
                            sharePinCode: project.sharePinCode,
                            isOpenShare: project.isOpenShare,
                            isShareNeedPinCode: project.isShareNeedPinCode,
                            projectName: project.name,
                            companyName: commonSettingData.name,
                          }}
                          exHeader={exHeader}
                          visitTypeShow={project.visitTypeShow}
                          _setOpenShare={_setOpenShare}
                          _addShare={_addShare}
                          _setOpenSharePassword={_setOpenSharePassword}
                        >
                          <Tooltip
                            placement="bottom"
                            title={intl.formatMessage({ id: 'shareToFriend' })}
                            overlayClassName="no-arrow"
                          >
                            <a>
                              <span className="iconfont iconbtn_share"></span>
                            </a>
                          </Tooltip>
                        </SharePopover>
                      )}

                      <Dropdown overlay={userMenu} trigger="click">
                        <a>
                          <svg
                            className={`icon ${styles.user}`}
                            aria-hidden="true"
                          >
                            <use xlinkHref="#iconbtn_info"></use>
                          </svg>
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                </Affix>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10%',
                  }}
                >
                  <InfoEmptyData description={intl.formatMessage({ id: 'categoryNoData' })} />
                </div>
              )}
            </div>
            <div className={styles.waterfullBody}>
              <MasonryImgs
                _pagePhotos={_pageCustomerPhoto}
                _saveMessage={_saveCustomerMessage}
                _setPhototHide={_setPhototHide}
                _addBookmarkPhoto={_addBookmarkPhoto}
                _getCustomerSingleDownloadUrl={_getCustomerSingleDownloadUrl}
                categoryId={selectedCategory}
                favoriteList={favoriteList}
                key={selectedCategory}
                exHeader={exHeader}
                checkIdentity={checkIdentity}
                setCallbackObj={setCallbackObj}
                callbackObj={callbackObj}
                backToLogin={backToLogin}
                project={project}
                intl={intl}
                canDownload={currentCategory}
              />
            </div>
          </>
        ) : loading&&(
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <InfoEmptyData description={intl.formatMessage({ id: 'cantRequest' })} />
          </div>
        )}
        {/* 下载弹窗 */}
        <InfDownLoadModal
          visible={downloadModalVisible}
          onCancel={() => setDownloadModalVisible(false)}
          onOk={downloadLogin}
        />
        {/* 用户验证弹窗 */}
        <InfPhoneCheckModal
          key={customerModalVisible}
          visible={customerModalVisible}
          onCancel={() => setCustomerModalVisible(false)}
          onOk={checkPhone}
        />
        <AboutModal
          visible={aboutVisible}
          handleCancel={() => setAboutVisible(false)}
          cameraman={showcase}
          commonSettingData={commonSettingData}
        />
        <CommonFooter websiteFooter={commonSettingData.websiteFooter} />
      </Layout>
    </div>
  )
}

export default CategoryPhotosPage
