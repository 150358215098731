// Servers
import {
  BookmarkPhotosSelector,
  BookmarksDownloadSelector,
  SetBookmarkPhotoHidenSelector,
} from 'selectors/bookmark/BookmarkSelectors'
import {
  CustomerCategoriesCanDownloadSelector,
  PhotoCategorySelector,
  PhotosListSelector,
  SetPhotoHidenSelector,
} from 'selectors/customerPhoto/CustomerPhotoSelectors'
// Selectors
import {
  ProjectCategorysSelector,
  ProjectInfoForLoginSelector,
  ProjectListSelector,
  ProjectSelector,
  SetBookmarkOpenSharePasswordSelector,
  SetBookmarkOpenShareSelector,
  SetOpenSharePasswordSelector,
  SetOpenShareSelector,
  DownloadCodeSelector
} from 'selectors/project/ProjectSelectors'
import {
  _addBookmarkPhoto,
  _cancelBookmark,
  _downLoadLogin,
  _getAllCanDownload,
  _getBookmarkAllForDownload,
  _getBookmarkDownloadUrl,
  _getBookmarkSharePhotoCategory,
  _getBookmarkSingleDownloadUrl,
  _getCustomerSingleDownloadUrl,
  _getDownloadUrl,
  _getFavoriteList,
  _getPhotoCategory,
  _getPhotosByBookmark,
  _getSharePhotoCategory,
  _pageBookmarkSharePhoto,
  _paymentTip,
  _pageCustomerPhoto,
  _pageSharePhoto,
  _saveMessage,
  _saveCustomerMessage,
  _setBookmarkPhotoHide,
  _setPhototHide,
  _getDownload,
  _getBookmarkDownload,
  _wechatMp,
  _emailLogin,
  _addShare,
} from 'servers/customerPhoto'
import {
  _getProjectById,
  _getProjectCategorys,
  _getProjectInfoForLogin,
  _getProjectsByCategory,
  _setBookmarkOpenShare,
  _setBookmarkOpenSharePassword,
  _setOpenShare,
  _setOpenSharePassword,
  _getAuthorization,
  _getWechat,
  _verifyDownloadCode,
} from 'servers/project'

// Actions

// Action Creators

// init data
const initPagination = {
  pageNo: 1,
  pageSize: 50,
}

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
// 获取项目类别
export const getProjectCategorys = async () => {
  const res = await _getProjectCategorys()
  return new Promise(function (resolve, reject) {
    resolve(ProjectCategorysSelector(res))
    reject(res)
  })
}

// 获取项目
export const getProjectsByCategory = async (params) => {
  const res = await _getProjectsByCategory(params)
  return new Promise(function (resolve, reject) {
    resolve(ProjectListSelector(res))
    reject(res)
  })
}

// 获取项目详情
export const getProjectById = async (params) => {
  const res = await _getProjectById(params)
  return new Promise(function (resolve, reject) {
    resolve(ProjectSelector(res))
    reject(res)
  })
}

// 查询客片项目登录情况
export const getProjectInfoForLogin = async (params) => {
  const res = await _getProjectInfoForLogin(params)
  return new Promise(function (resolve, reject) {
    resolve(ProjectInfoForLoginSelector(res))
    reject(res)
  })
}

// 获取客片
export const pageCustomerPhoto = async ({ params, exHeader }) => {
  const res = await _pageCustomerPhoto({
    params: { ...initPagination, ...params },
    exHeader,
  })
  return new Promise(function (resolve, reject) {
    resolve(PhotosListSelector(res))
    reject(res)
  })
}

// 获取客片分类
export const getPhotoCategory = async ({ exHeader }) => {
  const res = await _getPhotoCategory({ exHeader })
  return new Promise(function (resolve, reject) {
    resolve(PhotoCategorySelector(res))
    reject(res)
  })
}

// 获取客片-针对分享
export const pageSharePhoto = async ({ params, exHeader }) => {
  const res = await _pageSharePhoto({
    params: { ...initPagination, ...params },
    exHeader,
  })
  return new Promise(function (resolve, reject) {
    resolve(PhotosListSelector(res))
    reject(res)
  })
}

// 获取客片分类-针对分享
export const getSharePhotoCategory = async ({ exHeader, params }) => {
  const res = await _getSharePhotoCategory({ exHeader, params })
  return new Promise(function (resolve, reject) {
    resolve(PhotoCategorySelector(res))
    reject(res)
  })
}

// 获取收藏夹照片-针对分享
export const pageBookmarkSharePhoto = async ({ params, exHeader }) => {
  const res = await _pageBookmarkSharePhoto({
    params: { ...initPagination, ...params },
    exHeader,
  })
  return new Promise(function (resolve, reject) {
    resolve(PhotosListSelector(res))
    reject(res)
  })
}

// 获取收藏夹照片分类-针对分享
export const getBookmarkSharePhotoCategory = async ({ exHeader, params }) => {
  const res = await _getBookmarkSharePhotoCategory({ exHeader, params })
  return new Promise(function (resolve, reject) {
    resolve(PhotoCategorySelector(res))
    reject(res)
  })
}

// 设置客片隐藏
export const setPhototHide = ({ data, exHeader }) =>
  _setPhototHide({ data: SetPhotoHidenSelector(data), exHeader })

// 客片加入收藏夹
export const addBookmarkPhoto = ({ data, exHeader }) =>
  _addBookmarkPhoto({ data, exHeader })

// 获取文件夹
export const getFavoriteList = async ({ exHeader }) => {
  const res = await _getFavoriteList({ exHeader })
  return new Promise(function (resolve, reject) {
    resolve(PhotoCategorySelector(res))
    reject(res)
  })
}

// 获取文件夹图片
export const getPhotosByBookmark = async ({ params, exHeader }) => {
  const res = await _getPhotosByBookmark({
    params: { ...initPagination, ...params },
    exHeader,
  })
  return new Promise(function (resolve, reject) {
    resolve(BookmarkPhotosSelector(res))
    reject(res)
  })
}
// 修改留言
export const saveMessage = (data) => _saveMessage(data)
export const saveCustomerMessage = (data) => _saveCustomerMessage(data)

// 取消收藏
export const cancelBookmark = (data) => _cancelBookmark(data)

// 设置收藏夹图片隐藏
export const setBookmarkPhotoHide = ({ data, exHeader }) =>
  _setBookmarkPhotoHide({ data: SetBookmarkPhotoHidenSelector(data), exHeader })

// 下载收藏
export const downLoadLogin = (data) => _downLoadLogin(data)

// 查询客片允许下载的分类
export const getAllCanDownload = async (data) => {
  const res = await _getAllCanDownload(data)
  return new Promise(function (resolve, reject) {
    resolve(CustomerCategoriesCanDownloadSelector(res))
    reject(res)
  })
}

// 获取客片分类打包下载地址
export const getDownloadUrl = (data) => _getDownloadUrl(data)

export const getCustomerDownload = (data) => _getDownload(data)

// 获取客片单个下载地址
export const getCustomerSingleDownloadUrl = (data) =>
  _getCustomerSingleDownloadUrl(data)

// 查询收藏夹 - 含下载信息
export const getBookmarkAllForDownload = async (data) => {
  const res = await _getBookmarkAllForDownload(data)
  return new Promise(function (resolve, reject) {
    resolve(BookmarksDownloadSelector(res))
    reject(res)
  })
}

// 获取收藏夹打包下载地址
export const getBookmarkDownloadUrl = (data) => _getBookmarkDownloadUrl(data)

// 获取收藏夹打包下载地址 邮件
export const getBookmarkDownload = (data) => _getBookmarkDownload(data)

// 获取收藏夹单个下载地址
export const getBookmarkSingleDownloadUrl = (data) =>
  _getBookmarkSingleDownloadUrl(data)

//开关是否公开分享
export const setOpenShare = ({ data, exHeader }) =>
  _setOpenShare({ data: SetOpenShareSelector(data), exHeader })

// 开关是否公开分享设置密码
export const setOpenSharePassword = ({ data, exHeader }) =>
  _setOpenSharePassword({ data: SetOpenSharePasswordSelector(data), exHeader })

//开关收藏夹是否公开分享
export const setBookmarkOpenShare = ({ data, exHeader }) =>
  _setBookmarkOpenShare({ data: SetBookmarkOpenShareSelector(data), exHeader })

// 开关收藏夹是否公开分享设置密码
export const setBookmarkOpenSharePassword = ({ data, exHeader }) =>
  _setBookmarkOpenSharePassword({
    data: SetBookmarkOpenSharePasswordSelector(data),
    exHeader,
  })

export const paymentTip = ({ data, exHeader }) =>
  _paymentTip({ data, exHeader })

export const getAuthorization = (data) => _getAuthorization(data)
export const getWechat = (data) => _getWechat(data)
export const wechatMp = (data) => _wechatMp(data)
export const emailLogin = (data) => _emailLogin(data)
export const addShare = (data) => _addShare(data)
export const verifyDownloadCode = async (params) => {
  const res = await _verifyDownloadCode(params)
  return new Promise(function (resolve, reject) {
    resolve(DownloadCodeSelector(res))
    reject(res)
  })
}
