import LogoBK from './logo_bk.png'
import LogoWT from './logo_wt.png'
import MsgInfo from './msg_info.png'
import ProfotoDefault from './profoto_default.png'
import User from './user.png'
import Wxcode from './wx_code.png'
import DefaultCoverPNG from './default_cover.png'
import NoData from './img_nodata.png'
import LogoTemp from './logoTemp.png'
const Imgs = {
  LogoWT,
  LogoBK,
  ProfotoDefault,
  User,
  Wxcode,
  MsgInfo,
  DefaultCoverPNG,
  NoData,
  LogoTemp
}

export default Imgs
