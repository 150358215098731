import Api from 'apis/Api.js'
import request from 'utils/Request'

// 分页查询客片
export const _pageCustomerPhoto = async ({ params = {}, exHeader = {} }) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.pageList,
    headers: {
      exHeader,
    },
    params: params,
  })
}

// 查询客片分类
export const _getPhotoCategory = async ({ exHeader = {} }) => {
  return await request({
    method: 'get',
    url: Api.customerPhotoCategory.getAll,
    headers: {
      exHeader,
    },
  })
}

// 分页查询客片-针对分享
export const _pageSharePhoto = async ({ params = {}, exHeader = {} }) => {
  return await request({
    method: 'get',
    url: Api.customerPhoto.pageListForShare,
    headers: {
      exHeader,
    },
    params: params,
  })
}

// 查询客片分类-针对分享
export const _getSharePhotoCategory = async ({
  exHeader = {},
  params = {},
}) => {
  return await request({
    method: 'get',
    url: Api.customerPhotoCategory.getAllForShare,
    headers: {
      exHeader,
    },
    params: params,
  })
}

// 分页查询收藏夹-针对分享
export const _pageBookmarkSharePhoto = async ({
  params = {},
  exHeader = {},
}) => {
  return await request({
    method: 'get',
    url: Api.bookmarkPhoto.pageListForShare,
    headers: {
      exHeader,
    },
    params: params,
  })
}

// 查询收藏夹分类-针对分享
export const _getBookmarkSharePhotoCategory = async ({
  exHeader = {},
  params = {},
}) => {
  return await request({
    method: 'get',
    url: Api.bookmark.getAllForShare,
    headers: {
      exHeader,
    },
    params: params,
  })
}
// 查询客片允许下载的分类
/**
 *
 * @param {*} param0
 * params ==> 传递数据格式
 * {

  "downloadCode": "string" // 下载验证码
}
exHeader ==> token数据格式
{
  projectId,
  token
}
 */
export const _getAllCanDownload = async ({ params = {}, exHeader = {} }) => {
  return await request({
    method: 'get',
    url: Api.customerPhotoCategory.getAllCanDownload,
    headers: {
      exHeader,
    },
    params: params,
  })
}

// 获取客片分类打包下载地址
/**
 *
 * @param {*} param0
 * data ==> 传递数据格式
 * {
  "categoryIds": [
    0
  ],
  "downloadCode": "string"
}
exHeader ==> token数据格式
{
  projectId,
  token
}
 */
export const _getDownloadUrl = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.customerPhotoCategory.getDownloadUrl,
    headers: {
      exHeader,
    },
    data: data,
  })
}

export const _getDownload = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.customerPhotoCategory.getDownload,
    headers: {
      exHeader,
    },
    data: data,
  })
}

// 获取客片单个下载地址
/**
 *
 * @param {*} param0
 * data ==> 传递数据格式
 * {
  "customerPhotoIds": [
    0
  ],
  "downloadCode": "string"
}
exHeader ==> token数据格式
{
  projectId,
  token
}
 */
export const _getCustomerSingleDownloadUrl = async ({
  data = {},
  exHeader = {},
}) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.getDownloadUrl,
    headers: {
      exHeader,
    },
    data: data,
  })
}
// 客片设置隐藏
export const _setPhototHide = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.setHide,
    headers: {
      exHeader,
    },
    data: data,
  })
}

// 客片设置收藏
export const _addBookmarkPhoto = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.addBookmarkPhoto,
    headers: {
      exHeader,
    },
    data: data,
  })
}
// 查询收藏夹
export const _getFavoriteList = async ({ exHeader = {} }) => {
  return await request({
    method: 'get',
    url: Api.bookmark.getAll,
    headers: {
      exHeader,
    },
  })
}

// 分页查询文件夹图片
export const _getPhotosByBookmark = async ({ params = {}, exHeader = {} }) => {
  return await request({
    method: 'get',
    url: Api.bookmarkPhoto.getListForPage,
    headers: {
      exHeader,
    },
    params: params,
  })
}

// 修改留言
export const _saveMessage = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.bookmarkPhoto.addMessage,
    headers: {
      exHeader,
    },
    data: {
      ...data,
    },
  })
}
// 修改留言
export const _saveCustomerMessage = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.addMessage,
    headers: {
      exHeader,
    },
    data: {
      ...data,
    },
  })
}

// 取消收藏
export const _cancelBookmark = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.bookmarkPhoto.cancel,
    headers: {
      exHeader,
    },
    data: {
      ...data,
    },
  })
}

// 收藏夹设置隐藏
export const _setBookmarkPhotoHide = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.bookmarkPhoto.setHide,
    headers: {
      exHeader,
    },
    data: {
      ...data,
    },
  })
}

// 查询收藏夹 - 含下载信息
/**
 *
 * @param {*} param0
 * params ==> 传递数据格式
 * {

  "downloadCode": "string" // 下载验证码
}
exHeader ==> token数据格式
{
  projectId,
  token
}
 */
export const _getBookmarkAllForDownload = async ({
  params = {},
  exHeader = {},
}) => {
  return await request({
    method: 'get',
    url: Api.bookmark.getAllForDownload,
    headers: {
      exHeader,
    },
    params: params,
  })
}

// 收藏夹页面打包下载地址
/**
 *
 * @param {*} param0
 * data ==> 传递数据格式
 * {
  "bookmarkIds": [
    0
  ],
  "downloadCode": "string"
}
exHeader ==> token数据格式
{
  projectId,
  token
}
 */
export const _getBookmarkDownloadUrl = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.bookmark.getDownloadUrl,
    headers: {
      exHeader,
    },
    data: data,
  })
}

export const _getBookmarkDownload = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.bookmark.getDownload,
    headers: {
      exHeader,
    },
    data: data,
  })
}

//  推送b端收藏夹提示
export const _paymentTip = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.bookmark.paymentTip,
    headers: {
      exHeader,
    },
    data: data,
  })
}

// 获取收藏夹单个下载地址
/**
 *
 * @param {*} param0
 * data ==> 传递数据格式
 * {
  "customerPhotoIds": [
    0
  ],
  "downloadCode": "string"
}
exHeader ==> token数据格式
{
  projectId,
  token
}
 */
export const _getBookmarkSingleDownloadUrl = async ({
  data = {},
  exHeader = {},
}) => {
  return await request({
    method: 'post',
    url: Api.bookmarkPhoto.getDownloadUrl,
    headers: {
      exHeader,
    },
    data: data,
  })
}
//  下载登录
export const _downLoadLogin = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.downLoadLogin,
    headers: {
      exHeader,
    },
    data: data,
  })
}
//  微信登录
export const _wechatMp = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.wechatMp,
    headers: {
      exHeader,
    },
    data: data,
  })
}
//  邮箱登录
export const _emailLogin = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.emailLogin,
    headers: {
      exHeader,
    },
    data: data,
  })
}

//  添加分享
export const _addShare = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.customerPhoto.addShare,
    headers: {
      exHeader,
    },
    data: data,
  })
}
